import validate from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.3_ioredis@5.4.2_magicast@_acbfd4ecb3d46026b9719626d3e2d4a6/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45trailing_45slash_45global from "/usr/src/app/middleware/01.redirect-trailing-slash.global.ts";
import _02_45session_45global from "/usr/src/app/middleware/02.session.global.ts";
import _03_45tracking_45global from "/usr/src/app/middleware/03.tracking.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.2_db0@0.2.3_ioredis@5.4.2_magicast@_acbfd4ecb3d46026b9719626d3e2d4a6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45trailing_45slash_45global,
  _02_45session_45global,
  _03_45tracking_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "account-guard": () => import("/usr/src/app/middleware/account-guard.ts"),
  auth: () => import("/usr/src/app/middleware/auth.ts")
}